import { graphql } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { BlogLink, PageLayout, PageTitle } from "../components"
import { SEO, Utils } from "../utils"

export default ({ data }) => {
  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })

  const allFeaturedImages = data.allFile.edges || []
  const allPosts = data.allMarkdownRemark.edges || []
  const regex = /\/[blog].*\/|$/
  const featuredImageMap = Utils.getImageMap(allFeaturedImages, regex)

  const { filteredData, query } = state
  const filteredPosts = query !== "" ? filteredData : allPosts

  return (
    <PageLayout pageName="blog">
      <SEO title="Blog" />
      <PageTitle title="Blog" />
      <Container
        fluid
        className="p-3 w-auto text-left d-flex flex-wrap justify-content-center"
      >
        <Row>
          <Col md="8 offset-md-2">
            {filteredPosts.map(({ node }) => (
              <div key={node.id} className="p-3">
                <BlogLink
                  date={node.frontmatter.date}
                  to={node.fields.slug}
                  featuredImage={featuredImageMap[node.fields.slug]}
                  title={node.frontmatter.title}
                  subtitle={node.frontmatter.date}
                  excerpt={node.excerpt}
                  tags={node.frontmatter.tags}
                  time={node.frontmatter.read_time}
                />
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            author
            date(formatString: "DD MMMM, YYYY")
            read_time
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    allFile(
      filter: {
        extension: { eq: "jpg" }
        relativePath: { regex: "/feature/" }
        relativeDirectory: { regex: "/content/blog/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`
